<!-- 导航栏头部组件 -->
<template>
  <!-- 顶部菜单栏，支持暗黑模式切换 -->
  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    :class="{ 'dark-theme': currentTheme === 'dark' }"
    mode="horizontal">

    <!-- Logo区域 -->
   <!-- <el-menu-item index="logo" class="logo-item">-->
      <!--<img src="../assets/logo.png" alt="Logo" class="logo" />-->
     <!-- <span class="logo-text"></span>-->
    <!--</el-menu-item>-->

    <!-- 首页菜单项 -->
    <el-menu-item index="home" @click="handleHome" class="home-menu-item">
      <el-icon><House /></el-icon>首页
    </el-menu-item>

    <!-- 关于页面菜单项 -->
    <el-menu-item index="about" @click="$emit('show-about')" v-show="false">
      <el-icon><InfoFilled /></el-icon>关于
    </el-menu-item>

    <!-- 捐赠页面菜单项 -->
    <el-menu-item index="donate" @click="$emit('show-donate')">
      <el-icon><Coffee /></el-icon>捐赠
    </el-menu-item>

    <!-- 提交网站菜单项（当前隐藏） -->
    <el-menu-item index="reward" @click="$emit('show-reward')" v-show="false">
      <el-icon><Plus /></el-icon>提交网站
    </el-menu-item>

    <el-menu-item index="theme" @click="$emit('toggle-theme')">
      <el-icon>
        <Sunny v-if="currentTheme === 'dark'" />
        <Moon v-else />
      </el-icon>
    </el-menu-item>

    <!-- 搜索项，位于菜单中，样式根据主题动态调整 -->
    <el-menu-item index="search" class="search-item" style="margin-left: auto; position: relative; z-index: 3000;">
      <div class="search-container">
        <!-- 搜索引擎选择下拉框，根据当前主题调整样式 -->
        <el-select
          v-model="selectedEngine"
          class="search-engine-select"
          :teleported="false"
          :style="[
            currentTheme === 'dark' ? {
              backgroundColor: '#1a1a1a',
              color: '#e5e7eb',
              '--el-bg-color': '#1a1a1a',
              '--el-text-color-regular': '#e5e7eb',
              '--el-border-color': '#4c4d4f',
              '--el-fill-color-blank': '#1a1a1a',
              '--el-bg-color-overlay': '#1a1a1a',
              '--el-border-color-light': '#4c4d4f',
              '--el-text-color-placeholder': '#909399'
            } : {},
            { width: '250px' }
          ]"
        >
          <template #prefix>
            <el-icon class="search-engine-icon">
              <component :is="currentEngineIcon" />
            </el-icon>
          </template>
          <el-option
            v-for="engine in searchEngines"
            :key="engine.value"
            :label="engine.label"
            :value="engine.value"
            :class="{ 'dark-theme': currentTheme === 'dark' }"
            class="search-engine-option"
          >
            <div class="search-engine-option-content">
              <el-icon class="search-engine-icon">
                <component :is="engine.icon" />
              </el-icon>
              <span>{{ engine.label }}</span>
            </div>
          </el-option>
        </el-select>
        <!-- 搜索输入框，带有搜索按钮，根据当前主题调整样式 -->
        <el-input
          ref="searchInput"
          v-model="searchText"
          @keyup.enter="handleSearch"
          @input="handleSearchInput"
          class="search-input"
        >
          <template #append>
            <el-button @click="handleSearch">
              <el-icon><Search /></el-icon>
            </el-button>
            <!-- 清空按钮 -->
            <el-button 
              @click="clearSearch" 
              :class="{ 'dark-theme': currentTheme === 'dark' }"
              v-if="searchText"
              class="clear-button"
            >
              <el-icon><Close /></el-icon>
            </el-button>
          </template>
        </el-input>
      </div>
    </el-menu-item>
  </el-menu>
</template>

<script>
// 导入 Element Plus 图标组件
import { House, InfoFilled, Coffee, Plus, Moon, Sunny, Search, Close, ChromeFilled, Picture, VideoPlay } from '@element-plus/icons-vue'

export default {
  name: 'NavigationHeader',
  // 注册使用的图标组件
  components: {
    House,
    InfoFilled,
    Coffee,
    Plus,
    Moon,
    Sunny,
    Search,
    Close,
    ChromeFilled,
    Picture,
    VideoPlay
  },
  // 组件属性定义
  props: {
    // 当前激活的菜单项索引
    activeIndex: {
      type: String,
      default: ''
    },
    // 当前主题（light/dark）
    currentTheme: {
      type: String,
      required: true
    }
  },
  // 定义组件可触发的事件
  emits: ['show-about', 'show-donate', 'show-reward', 'select', 'toggle-theme', 'test', 'search-input'],
  data() {
    return {
      searchText: '',
      selectedEngine: 'google',
      searchEngines: [
        {
          label: 'Google',
          value: 'google',
          url: 'https://www.google.com/search?q=%s',
          icon: 'ChromeFilled'
        },
        {
          label: 'Google Images',
          value: 'google_images',
          url: 'https://www.google.com/search?tbm=isch&q=%s',
          icon: 'Picture'
        },
        {
          label: 'Google Videos',
          value: 'google_videos',
          url: 'https://www.google.com/search?tbm=vid&q=%s',
          icon: 'VideoPlay'
        },
        {
          label: 'Bing',
          value: 'bing',
          url: 'https://www.bing.com/search?q=%s',
          icon: 'Search'
        },
        {
          label: 'Baidu',
          value: 'baidu',
          url: 'https://www.baidu.com/s?wd=%s',
          icon: 'Search'
        },
        {
          label: 'Google Scholar',
          value: 'scholar',
          url: 'https://scholar.google.com/scholar?q=%s',
          icon: 'Search'
        },
        {
          label: 'GitHub',
          value: 'github',
          url: 'https://github.com/search?q=%s',
          icon: 'Search'
        }
      ]
    }
  },
  computed: {
    currentEngineIcon() {
      return this.searchEngines.find(engine => engine.value === this.selectedEngine)?.icon || 'Search'
    }
  },
  methods: {
    // 处理菜单项选择事件
    handleSelect(key) {
      this.$emit('select', key)
    },
    // 处理首页跳转
    handleHome() {
      window.location.href = 'http://192.168.31.81:8080/';
    },
    // 处理空格键输入
    handleSpace() {
      this.searchText += ' ';
      this.$emit('search-input', this.searchText);
    },
    // 处理搜索
    handleSearch(event) {
      event.stopPropagation();
      if (!this.searchText) return;
      const searchUrl = this.searchEngines.find(engine => engine.value === this.selectedEngine)?.url;
      if (searchUrl) {
        window.open(searchUrl.replace('%s', encodeURIComponent(this.searchText)), '_blank');
      }
    },
    handleSearchInput(value) {
      this.$emit('search-input', value);
    },
    clearSearch() {
      this.searchText = '';
      this.$emit('search-input', '');  // 触发搜索输入变化事件
    }
  }
}
</script>

<style scoped>
/* Logo 图片样式 */
.logo {
  width: 64px;
  height: 64px;
}

/* Logo 文字样式 */
.logo-text {
  font-size: 32px;  
  font-weight: bold;
  color: #2f2f30;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* 暗黑模式下 Logo 文字颜色 */
.dark-theme .logo-text {
  color: #ffffff;
}

/* 暗黑模式下菜单栏样式 */
.dark-theme.el-menu {
  --el-menu-bg-color: #1a1a1a;
  --el-menu-text-color: #ffffff;
  --el-menu-hover-text-color: var(--el-color-primary);
  --el-menu-active-color: var(--el-color-primary);
  --el-menu-hover-bg-color: #2c2c2c;
  border-bottom: 1px solid #2c2c2c !important;
}

/* 暗黑模式下菜单项文字颜色 */
.dark-theme .el-menu-item {
  --el-text-color-regular: #ffffff;
}

/* 暗黑模式下菜单项悬停效果 */
.dark-theme .el-menu-item:not(.is-active):hover {
  background-color: #2c2c2c !important;
}

/* Logo 容器样式 */
.logo-item {
  height: var(--el-menu-item-height) !important;
  padding: 0 10px !important;
  display: flex !important;
  align-items: center !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none !important;
}

/* 确保导航栏紧贴顶部 */
.el-menu {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

/* 移除默认边框 */
.el-menu-demo {
  border-bottom: none !important;
}

/* 去除菜单项的边距 */
.el-menu-item {
  margin: 0 !important;
  height: var(--el-menu-item-height) !important;
  line-height: var(--el-menu-item-height) !important;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .search-container {
    gap: 5px;
    position: relative;
    z-index: 3000;
  }
  
  .search-item {
    position: relative !important;
    z-index: 3000 !important;
  }
  
  .search-engine-select {
    width: 80px;
  }

  .search-input {
    width: 180px !important;
  }

  .logo-item {
    display: none !important;
  }
  
  .logo, .logo-text {
    display: none !important;
  }

  /* 调整首页菜单项位置 */
  .el-menu-demo .home-menu-item.el-menu-item {
    margin-left: 48px !important;
    padding-left: 20px !important;
  }

  /* 确保下拉菜单显示在最上层 */
  .el-select__popper {
    z-index: 3001 !important;
  }
}

/* 全局确保搜索相关元素在最上层 */
.el-select__popper {
  z-index: 3001 !important;
}

.search-item {
  position: relative !important;
  z-index: 3000 !important;
}

.search-container {
  position: relative;
  z-index: 3000;
}

/* 搜索引擎选项暗黑模式样式 */
.search-engine-option.dark-theme {
  background-color: #1a1a1a !important;
  color: #e5e7eb !important;
}

.search-engine-option.dark-theme:hover {
  background-color: #2c2c2c !important;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 3000; /* 确保在左侧导航之上 */
}

.search-engine-select {
  width: 100px;
}

.search-engine-icon {
  margin-right: 8px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}

.search-engine-option {
  display: flex;
  align-items: center;
}

/* 搜索按钮和清空按钮暗黑模式样式 */
.search-button.dark-theme,
.clear-button.dark-theme {
  background-color: #1a1a1a !important;
  border-color: #4c4d4f !important;
  color: #e5e7eb !important;
}

.search-button.dark-theme:hover,
.clear-button.dark-theme:hover {
  background-color: #2c2c2c !important;
  border-color: #4c4d4f !important;
}

/* 调整搜索按钮和清空按钮的样式 */
.search-button {
  margin-left: -21px !important;  /* 减小搜索按钮和输入框之间的间距 */
}

.clear-button {
  margin-left: 8px !important;  /* 清空按钮和搜索按钮之间的间距 */
}

:deep(.el-menu--popup) {
  background-color: var(--el-bg-color) !important;
}

.navigation-header-dark :deep(.el-menu--popup) {
  background-color: #1a1a1a !important;
}

.navigation-header-dark :deep(.el-menu--popup .el-menu-item) {
  color: #a8a8a8 !important;
  background-color: #1a1a1a !important;
}

.navigation-header-dark :deep(.el-menu--popup .el-menu-item:hover) {
  color: #ffffff !important;
  background-color: #2c2c2c !important;
}

.search-engine-option-content {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>