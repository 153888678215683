export const entertainment = [
  {
    name: '游戏资源',
    icon: 'Trophy',
    emoji: '🎮',
    links: [
      { name: 'Steam', url: 'https://store.steampowered.com/', description: '游戏平台' },
      { name: 'Epic Games', url: 'https://www.epicgames.com/', description: '游戏平台' },
      { name: 'PlayStation', url: 'https://www.playstation.com/', description: '索尼游戏平台' },
      { name: 'flysheep6', url: 'https://www.flysheep6.com/', description: '各类游戏' },
      { name: 'Game520', url: 'https://www.gamer520.com/pcgame', description: '游戏资源' },
      { name: 'GOG', url: 'https://www.gog.com/', description: '无DRM游戏平台' },
      { name: '老男人游戏', url: 'https://www.oldmantvg.net/', description: '游戏资源' },
      { name: 'itch.io', url: 'https://itch.io/', description: '独立游戏平台' },
      { name: '游戏藏宝湾', url: 'https://www.iopq.net/', description: '各种单机游戏' },
      { name: 'byrutgame', url: 'https://byrutgame.org/', description: '各类游戏' },
      { name: '游戏资源站', url: 'https://www.gamer.com.tw/', description: '游戏资源' },
      { name: '游民星空', url: 'https://www.gamersky.com/', description: '游戏资源' },
      { name: '雷神加速器', url: 'https://www.leigod.com/', description: '游戏加速器' },
      { name: '迅游网游', url: 'https://www.xunyou.com/', description: '游戏加速器' },
      { name: 'WeGame', url: 'https://www.wegame.com.cn/home/', description: '游戏平台' },
      { name: '战网', url: 'https://shop.battlenet.com.cn/', description: '暴雪' },
      { name: 'dd373', url: 'https://www.dd373.com/', description: '游戏交易平台' },
      { name: '5173', url: 'https://5173.com/', description: '游戏交易平台' },
      { name: 'rutracker', url: 'https://rutracker.org/forum/index.php', description: '俄罗斯各类资源下载网站' },
      { name: '网易游戏', url: 'https://game.163.com/', description: '网易游戏' },
    ]
  },
  {
    name: '学习资源',
    icon: 'Trophy',
    emoji: '📚',
    links: [
      { name: '可汗学院', url: 'https://zh.khanacademy.org/', description: 'Khan Academy免费学习' },
      { "name": "TED", "url": "https://www.ted.com/", "description": "一个提供全球知名演讲视频的网站，涵盖科学、技术、教育等领域，鼓励知识共享和创新。" },
      { name: 'edX', url: 'https://www.edx.org/', description: 'edX' },
      { name: 'FutureLearn', url: 'https://www.futurelearn.com/', description: 'FutureLearn' },
      { name:' Medium', url: 'https://medium.com/', description: 'Medium'},
      { name: 'LeetCode', url: 'https://leetcode.com', description: '编程练习与面试准备，涵盖各种编程语言与算法题目' },
      { name: 'FreeCodeCamp', url: 'https://www.freecodecamp.org', description: '全套免费编程学习课程，涵盖前端、后端、数据科学等领域' },
      { name: 'Codecademy', url: 'https://www.codecademy.com', description: '提供互动式编程学习体验，适合初学者' },
      { name: 'W3Schools', url: 'https://www.w3schools.com', description: '详细的HTML、CSS、JavaScript、Python等编程语言教程' },
      { name: 'Udemy', url: 'https://www.udemy.com', description: '各种技术和非技术课程，许多课程有折扣和特价活动' },
      { name: 'Coursera', url: 'https://www.coursera.org', description: '顶尖大学和公司合作的在线课程' },
      { name: 'Pluralsight', url: 'https://www.pluralsight.com', description: '高质量的IT和软件开发课程，适合职业开发者' },
      { name: 'Kaggle', url: 'https://www.kaggle.com', description: '数据科学与机器学习竞赛平台，提供丰富的学习资源和数据集' },
      { name: 'Fast.ai', url: 'https://www.fast.ai', description: '免费的深度学习课程，注重实践和效率' },
      { name: 'DeepLearning.AI', url: 'https://www.deeplearning.ai', description: '由Andrew Ng创办，提供深度学习和人工智能相关课程' },
      { name: 'DataCamp', url: 'https://www.datacamp.com', description: '专注于数据科学和机器学习的互动式学习平台' },
      { name: 'Skillshare', url: 'https://www.skillshare.com', description: '提供创意类课程，包括插画、摄影、动画等' },
      { name: 'Lynda', url: 'https://www.linkedin.com/learning/', description: '创意和技术课程，适合多种技能的学习' },
      { name: 'Domestika', url: 'https://www.domestika.org', description: '提供创意艺术领域的在线课程' },
      { name: 'LawLine', url: 'https://www.lawline.com', description: '提供法律相关的在线课程' },
      { name: 'Harvard Law School Online Learning', url: 'https://online-learning.harvard.edu/subject/law', description: '哈佛大学提供的法律在线课程资源' },
      { name: 'LinkedIn Learning', url: 'https://www.linkedin.com/learning/', description: '提供各种商业、法律、技术类课程' },
      { name: '菜鸟教程', url: 'https://www.runoob.com/', description: '菜鸟教程，提供各种编程语言的入门教程' },
      { name: 'arxiv', url: 'https://arxiv.org/', description: 'arXiv 是一项免费分发服务，也是一个开放获取的档案库，其中包含近 240 万篇学术文章，涉及物理学、数学、计算机科学、定量生物学、定量金融学、统计学、电气工程和系统科学以及经济学等领域。本网站上的资料未经 arXiv 同行评审。' },
      { name: 'Instructables', url: 'https://www.instructables.com/', description: 'Instructables 是一个社区，提供各种 DIY 项目的指导和资源' },
      { "name": "Lifehacker", "url": "https://www.lifehacker.com/", "description": "一个关于生活窍门和技巧的网站，分享提高效率、节省时间、增加生产力的方法和策略。" },
      { name: 'hellogithub', url: 'https://hellogithub.com/', description: 'github资源汇聚' },
      

    ]
  },

  {
    name: '逆向工具',
    icon: 'Trophy',
    emoji: '🔧',
    links: [
      { name: 'processhacker', url: 'https://processhacker.sourceforge.io/', description: 'ProcessHacker' },
      { name: 'x64dbg', url: 'https://x64dbg.com/', description: 'x64dbg' },
      { name: 'ghidra-sre', url: 'https://ghidra-sre.org/', description: 'Ghidra-SRE' },
      { name: 'IDA', url: 'https://hex-rays.com/', description: 'Hex-Rays' },
      { name: 'x-ways', url: 'https://x-ways.net/', description: 'x-ways' },
      { name: 'systeminformer', url: 'https://systeminformer.com/', description: '一个免费的、强大的、多用途的工具，可以帮助您监控系统资源、调试软件和检测恶意软件。' },
      { name: 'cheatengine', url: 'https://www.cheatengine.org/', description: 'Cheat Engine 是一个开源开发环境，专注于修改或修改在 Windows 下运行的单人游戏，以便您可以根据个人喜好玩游戏。您可以通过各种修改选项根据需要让游戏变得更难或更容易。它还附带许多其他功能来帮助调试游戏和其他应用程序，并通过让您检查后门对内存的修改来保护您的系统。Cheat Engine 还附带广泛的脚本支持，因此经验丰富的开发人员可以轻松创建和共享自己的应用程序。' },
      { name: 'Windbg', url: 'https://learn.microsoft.com/en-us/windows-hardware/drivers/debugger/', description: 'Windows 调试' },
      { name: 'vmpsoft', url: 'https://vmpsoft.com/', description: '保护您的软件免受破解、分析和逆向工程。确保您的工作安全可靠。' },
      { name: 'apktool', url: 'https://apktool.org/', description: 'APKtool 是一个开源的、跨平台的工具，用于修改、反编译和重新打包 Android 应用程序。它允许您轻松地修改应用程序的代码，以实现自定义功能，并且可以将修改后的代码重新打包成新的 APK 文件。' },
      { name: 'imhex', url: 'https://imhex.werwolv.net/', description: '免费开源十六进制编辑器' },
      { name: 'Detect It Easy', url: 'https://github.com/horsicq/DIE-engine', description: '一款专业的文件分析工具，专为开发者、逆向工程师和安全专家设计，旨在快速识别可执行文件和库的类型及其详细信息。该工具能够分析多种文件格式，包括可执行文件、动态链接库（DLL）和图像文件，提供有关文件版本、依赖关系、资源和签名的信息，帮助用户深入了解文件的结构和特性' },
      { name: '爱盘', url: 'https://down.52pojie.cn/', description: '52破解网盘工具下载' },
      
    ]
  },
  {
    name: '软件逆向',
    icon: 'Trophy',
    emoji: '🔧',
    links: [
      { name: '吾爱破解', url: 'https://52pojie.cn/', description: '吾爱破解'},
      { name: 'unknowncheats', url: 'https://www.unknowncheats.me/forum/index.php', description: 'Game Hacking' },
      { name: 'memoryhackers', url: 'https://memoryhackers.org/', description: 'Memory Hackers' },
      { name: 'Hackforums', url: 'https://hackforums.net/', description: 'HackForums' },
      { name: 'guidedhacking', url: 'https://guidedhacking.com/ ', description: '游戏黑客' },
      { name :'看雪',url:'https://www.kanxue.com/',description:'看雪'},
      { name: 'gamehacking.academy', url: 'https://gamehacking.academy/', description: '游戏黑客' },
      { name:'sms-activate',url:'  https://sms-activate.guru/cn',description:'短信激活平台'},
      { name: 'hackforums.net', url: 'https://hackforums.net/', description: 'HackForums' },
    
    ]
  },




  {
    name: '磁力下载',
    icon: 'cpu',
    emoji: '🔧',
    links: [
      { name: 'piratebayproxy', url: 'https://piratebayproxy.net/zh/', description: '海盗湾' },
      { name: '1337x', url: 'https://1337x.to/', description: '磁力下载' },
      { name: 'yts', url: 'https://yts.mx/', description: '以最小的文件大小浏览和下载 720p、1080p、2160p 4K 和 3D 质量的 YIFY 电影' },
      { name: 'torrends', url: 'https://torrends.to/', description: 'Torrends' },
      { name: '迅雷', url: 'https://www.xunlei.com/', description: '迅雷' },
    ]
  },
  {
    name: 'Email',
    icon: 'tools',
    emoji: '✉️',
    links: [
      { name: 'gmail', url: 'https://mail.google.com/', description: 'Gmail' },
      { name: 'outlook', url: 'https://outlook.live.com/mail/', description: 'Outlook' },
      { name: 'Yahoo Mail', url: 'https://mail.yahoo.com/', description: 'Yahoo Mail' },
      { name: 'protonmail', url: 'https://account.proton.me/mail', description: 'ProtonMail' },
      { name: 'zoho', url: 'https://www.zoho.com/mail/', description: 'Zoho Mail' },
      { name: 'fastmail', url: 'https://www.fastmail.com/', description: 'Fastmail' },
      { name: 'yandex', url: 'https://mail.yandex.com/', description: 'Yandex Mail' },
      { name: 'icloud', url: 'https://www.icloud.com/', description: 'iCloud' },
      { name: 'aol', url: 'https://webmail.aol.com/', description: 'AOL Mail' },

      { name: 'thunderbird', url: 'https://www.thunderbird.net/', description: '遇见 Thunderbird，一个给您充分自由的邮件和生产力应用。' },
    ]
  },
  {
    name: '地图',
    icon: 'tools',
    emoji: '🗺️',
    links: [
      { name: '百度地图', url: 'https://www.baidu.com/map/', description: '百度地图' },
      { name: '高德地图', url: 'https://www.amap.com/', description: '高德地图' },
      { name: '腾讯地图', url: 'https://map.qq.com/', description: '腾讯地图' },
      { name: 'google maps', url: 'https://www.google.com/maps/', description: 'Google Maps' },
      { name: 'mapbox', url: 'https://www.mapbox.com/', description: 'Mapbox' },
      { name: 'open street map', url: 'https://www.openstreetmap.org/', description: 'OpenStreetMap' },
      { name: 'mapy.cz', url: 'https://mapy.cz/', description: 'Mapy.cz' },
      { name: 'mapbox', url: 'https://www.mapbox.com/', description: 'Mapbox' },
      { name: 'openlayers', url: 'https://openlayers.org/', description: 'OpenLayers' },
      { name: '谷歌地球', url: 'https://www.google.com/intl/zh-TW/earth/about/', description: 'Google Earth' },
    ]
  },


];
